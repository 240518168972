import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Components/Styles/NavBar.module.css';
import burger from './Assets/burger.svg';
import close from './Assets/close.svg';

const NavBar = ({ isBlack }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.burger} onClick={toggleMenu}>
        <img src={isOpen ? close : burger} alt="menu button" width={30} title={isOpen ? 'close' : 'open menu'}/>
      </div>
      <ul className={`${styles.navList} ${isOpen ? styles.active : ''}`}>
        <li className={isBlack ? styles.blackNavItem : styles.navItem}>
          <Link to="/" onClick={toggleMenu}>Glavna stran</Link>
        </li>
        <li className={isBlack ? styles.blackNavItem : styles.navItem}>
          <Link to="/about" onClick={toggleMenu}>O nas</Link>
        </li>
        <li className={isBlack ? styles.blackNavItem : styles.navItem}>
          <Link to="/list" onClick={toggleMenu}>Hoteli</Link>
        </li>
        <li className={isBlack ? styles.blackNavItem : styles.navItem}>
          <Link to="/hotels" onClick={toggleMenu}>Vroče ponudbe</Link>
        </li>
        <li className={isBlack ? styles.blackNavItem : styles.navItem}>
          <a href="#contacts" onClick={toggleMenu}>Povezava</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;

