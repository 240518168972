import React from 'react'
import styles from '../Components/Styles/Privacy.module.css';
import NavBar from './NavBar';
import Footer from './Footer';

const Cookies = () => {
  return (
    <>
    <NavBar isBlack={true}/>
    <div className={styles.container}>
      <h1>Politika piškotkov za bar-srece.com</h1>
      <p>
      To je pravilnik o piškotkih za spletno stran bar-srece.com, ki je na voljo na naslovu bar-srece.com
      </p>

      <h2>Kaj so piškotki</h2>
      <p>
      Kot je običajno pri skoraj vseh strokovnih spletnih mestih, tudi to spletno mesto uporablja piškotke, majhne datoteke, ki se prenesejo v vaš računalnik in izboljšajo vašo izkušnjo. Na tej strani je opisano, katere podatke zbiramo, kako jih uporabljamo in zakaj moramo te piškotke včasih shraniti. Prav tako bomo delili informacije o tem, kako lahko preprečimo shranjevanje teh piškotkov, vendar lahko to oslabi ali „pokvari“ nekatere elemente funkcionalnosti mestnih spletnih strani.
      </p>

      <h2>Kako uporabljamo piškotke</h2>
      <p>
      Piškotke uporabljamo iz različnih razlogov, opisanih v nadaljevanju. Na žalost v večini primerov v industriji ni privzetih možnosti za onemogočanje piškotkov, ne da bi pri tem popolnoma onemogočili funkcionalnost in funkcije, kot jih dodajajo tej spletni strani. Priporočljivo je, da pustite vse piškotke, če niste prepričani, ali jih potrebujete ali ne, če se uporabljajo za zagotavljanje storitve, ki jo uporabljate.
      </p>

      <h2>Onemogočite piškotke</h2>

      <p>Shranjevanje piškotkov lahko preprečite tako, da spremenite nastavitve brskalnika (glejte pomoč brskalnika, kako to storiti). Upoštevajte, da bo onemogočanje piškotkov vplivalo na delovanje te in številnih drugih funkcij spletnih mest, ki jih obiskujete. Če onemogočite piškotke, običajno onemogočite tudi nekatere funkcije IN lastnosti tega spletnega mesta. Zato ni priporočljivo, da onemogočite piškotke.</p>
      

      <h2>Informacijski piškotki, ki jih nastavljamo</h2>
      <p>
      Piškotki za nastavitve spletnega mesta
      </p>
      <p>Da bi vam zagotovili dobro izkušnjo pri uporabi tega spletnega mesta, vam ponujamo funkcijo, ki vam omogoča vnos nastavitev pri uporabi tega spletnega mesta. Da bi si zapomnili vaše nastavitve, moramo namestiti piškotke, tako da lahko te informacije pridobimo vsakič, ko v interakciji s spletnim mestom uporabite vaše nastavitve.</p>
      
      <h2>Piškotki tretjih oseb</h2>
      <p>
      V nekaterih posebnih primerih uporabljamo tudi piškotke zaupanja vrednih tretjih oseb. V naslednjem razdelku je podrobneje opisano, katere piškotke tretjih oseb lahko srečate na tem spletnem mestu.
      </p>
      <p>
      To spletno mesto uporablja Google Analytics, eno od najbolj razširjenih in zaupanja vrednih orodij za analitične rešitve v spletnem svetu, saj nam pomaga razumeti, kako uporabljate spletno mesto in kako lahko izboljšamo vašo izkušnjo. S temi piškotki lahko na primer spremljamo čas, ki ga preživite na spletnem mestu, in strani, ki jih obiščete, zato lahko še naprej ustvarjamo zanimivo vsebino.
      </p>
      <p>
      Več o piškotkih Google Analytics lahko izveste na uradnem spletnem mestu Google Analytics.
      </p>

      <h2>Več informacij</h2>
      <p>
      Mamy nadzieję, że wyjaśniliśmy Ci sprawę i jak wspomnieliśmy wcześniej, jeśli jest coś takiego nie jesteś pewien, czy ich potrzebujesz, czy nie, zazwyczaj bezpieczniej jest pozostawić na wszelki wypadek włączoną obsługę plików cookie interakcje z jedną z funkcji, z których korzystasz na naszej stronie internetowej.
      </p>
    </div>
    <Footer/>
    </>
  )
}

export default Cookies;
