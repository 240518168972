import React from 'react';
import styles from '../Components/Styles/Privacy.module.css';
import NavBar from './NavBar';
import Footer from './Footer';

const Privacy = () => {
  return (
    <>
    <NavBar isBlack={true}/>
    <div className={styles.container}>
      <h1>Polityka prywatności bar-srece.com</h1>
      <p>
        Na spletnem mestu bar-srece.com, ki je dostopno na naslovu
        bar-srece.com, je zaupnost obiskovalcev ena od naših glavnih prednostnih
        nalog. Ta dokument o politiki zasebnosti vsebuje vrsto podatkov, ki se
        zbirajo in beležijo na spletnem mestu bar-srece.com, in način njihove
        uporabe.
      </p>
      <p>
        Če imate dodatna vprašanja ali potrebujete več informacij o naših
        smernicah, se obrnite na nas zasebno.
      </p>
      <p>
        Niniejsza polityka prywatności dotyczy wyłącznie naszej działalności
        online i dotyczy odwiedzający naszą stronę internetową z w odniesieniu
        do informacji udostępnianych i/lub gromadzonych w serwisie
        bar-srece.com. Ta polityka nie jest dotyczy wszystkich danych
        gromadzonych w trybie offline lub za pośrednictwem kanałów innych niż ta
        strona internetowa.
      </p>
      <h2>Soglasje</h2>
      <p>
        Z uporabo našega spletnega mesta sprejemate naš pravilnik o zasebnosti s
        pogoji in določili ter se strinjate, da vas zavezujejo.
      </p>
      <h2>Podatki, ki jih zbiramo</h2>
      <p>
        Osebne podatke, ki jih morate posredovati, in razloge, zakaj jih morate
        posredovati, da boste prepričani, vam bomo pojasnili, ko vas bomo
        prosili za posredovanje osebnih podatkov.
      </p>
      <p>
        Če nas kontaktirate neposredno, lahko prejmemo dodatne informacije o
        vas, kot so vaše ime, e-poštni naslov, telefonska številka, vsebina
        vašega sporočila in/ali priponk, ki nam jih lahko pošljete, ter kakršne
        koli druge informacije, ki jih lahko posredujete.
      </p>
      <p>
        Ko registrirate račun, vas lahko prosimo za kontaktne podatke, vključno
        z elementi, kot so vaše ime, ime podjetja, naslov, e-poštni naslov in
        telefonska številka.
      </p>
      <h2>Kako uporabljamo vaše podatke</h2>
      <p>Wykorzystujemy zebrane informacje na wiele sposobów, w tym:</p>
      <p>Dostava, delovanje in vzdrževanje našega spletnega mesta.</p>
      <p>Izboljšanje, prilagoditev in razvoj našega spletnega mesta.</p>
      <p>razumevanje in analiziranje, kako uporabljate naše spletno mesto.</p>
      <p>Razvoj novih izdelkov, storitev, funkcij in lastnosti</p>
      <p>
        Z vami komuniciramo neposredno ali prek enega od naših partnerjev,
        vključno s službo za stranke, da vam zagotovimo posodobitve in druge
        informacije v zvezi s spletnim mestom ter za trženjske in promocijske
        namene.
      </p>
      <p>Pošiljam vam e-pošto</p>
      <p> Odkrivanje in preprečevanje goljufij</p>
      <h2>Dnevniške datoteke</h2>
      <p>
        bar-srece.com sledi standardnemu postopku za uporabo dnevniških datotek.
        Te datoteke beležijo obiskovalce, ko obiščejo spletna mesta. To počnejo
        vsa podjetja za spletno gostovanje in so del analize storitev spletnega
        gostovanja. Informacije, zbrane v dnevniških datotekah, vključujejo
        naslove internetnega protokola (IP), vrsto brskalnika in ponudnika
        internetnih storitev (ISP), datum in čas, strani, na katere se
        napotuje/izhaja, ter po potrebi število klikov. Niso povezane z nobenimi
        osebnimi podatki. Namen teh podatkov je analiza trendov, upravljanje
        spletnega mesta, sledenje gibanju uporabnikov na spletnem mestu in
        zbiranje demografskih podatkov.
      </p>
      <h2>Pravilnik o zasebnosti za oglaševalske partnerje</h2>
      <p>
        Na tem seznamu si lahko ogledate politiko zasebnosti vsakega od
        oglaševalskih partnerjev, ki jih imate bar-srece.com.
      </p>
      <p>
        Tretje osebe, oglaševalski strežniki ali oglaševalske mreže uporabljajo
        tehnologije, kot so piškotki, JavaScript ali web beacons se uporablja v
        ustreznih oglasov in povezav, prikazanih na bar-srece.com, ki so
        poslani neposredno v vaš brskalnik. Pri tem samodejno prejmejo vaš
        naslov IP. Te tehnologije se uporabljajo za merjenje učinkovitosti
        njihovih oglaševalskih kampanj in/ali za prilagajanje oglaševalske
        vsebine, kot jo vidite na spletnih mestih, ki jih obiščete.
      </p>
      <p>
        Upoštevajte, da bar-srece.com nima dostopa do piškotkov, ki jih
        uporabljajo oglaševalci tretjih oseb, ali nadzora nad njimi.
      </p>
      <h2>Politika zasebnosti tretje osebe</h2>
      <p>
        Pravilnik o zasebnosti commmmms ne velja za druge oglaševalce ali
        spletna mesta omrežja. Zato vam priporočamo, da za več informacij
        preberete ustrezne politike zasebnosti teh tretjih oglaševalskih
        strežnikov. Ta lahko vključuje njihove prakse in navodila za opustitev
        določenih možnosti.
      </p>
      <p>
        Piškotke lahko onemogočite z možnostmi posameznega brskalnika.
        Podrobnejše informacije Informacije o ravnanju s piškotki v posameznih
        brskalnikih lahko najdete v brskalniku ali spletnih straneh.
      </p>
      <h2>
        Pravice do zasebnosti v skladu z zakonom CCPA (ne prodajajte mojih
        osebnih podatkov)
      </h2>
      <p>
        V skladu s CCPA imajo potrošniki v Kaliforniji med drugim pravico do:
      </p>

      <p>
        od podjetja, ki zbira osebne podatke potrošnikov, zahtevati, da razkrije
        kategorije in KONKRETNOSTI osebnih podatkov, ki jih je podjetje zbralo o
        potrošnikih.
      </p>

      <p>
        od podjetja zahtevati, da izbriše vse osebne podatke potrošnikov, ki jih
        je podjetje zbralo.
      </p>

      <p>
        Od podjetja, ki prodaja osebne podatke potrošnikov, zahtevati, da ne
        prodaja vaših osebnih podatkov.
      </p>

      <p>
        Če pošljete zahtevek, imamo na voljo en mesec časa za odgovor. Če želite
        uveljavljati katero koli od teh pravic, se obrnite na nas.
      </p>
      <h2>Pravice družbe RODO v zvezi z varstvom podatkov</h2>
      <p>
        Želimo se prepričati, da ste v celoti seznanjeni z vsemi svojimi
        pravicami glede varstva podatkov. Vsak uporabnik ima pravico do:
      </p>

      <p>
        pravico do dostopa - imate pravico zahtevati kopijo svojih osebnih
        podatkov. Za to storitev vam lahko zaračunamo manjšo pristojbino.
      </p>

      <p>
        Pravica do popravka - imate pravico zahtevati, da popravimo vse podatke,
        za katere menite, da so napačni, da so netočni. Prav tako imate pravico
        zahtevati, da zahtevane informacije posredujemo nepopolne.
      </p>

      <p>
        Pravica do izbrisa - v nekaterih primerih imate pravico zahtevati, da
        izbrišemo vaše osebne podatke o razmerju.
      </p>

      <p>
        Pravica do omejitve obdelave - v nekaterih primerih imate pravico
        zahtevati, da omejimo obdelavo vaših podatkov o osebnem razmerju, pod
        določenimi pogoji.
      </p>

      <p>
        Pravica do ugovora obdelavi - pod določenimi pogoji imate pravico
        ugovarjati, da obdelujemo vaše osebne podatke.
      </p>

      <p>
        Pravica do prenosljivosti podatkov - pod določenimi pogoji imate pravico
        zahtevati prenos podatkov, ki jih imamo zbrane, na drugo organizacijo
        ali neposredno na vas.
      </p>

      <p>
        Če pošljete zahtevo, imamo en mesec časa za odgovor. Če želite
        uveljavljati katero koli od teh pravic, se obrnite na nas.
      </p>
    </div>
    <Footer/>
    </>
  );
};

export default Privacy;
