import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Header from './Components/Header';
import About from './Components/About';
import List from './Components/List';
import Slider from './Components/SliderComponent';
import Footer from './Components/Footer';
import Privacy from './Components/Privacy';
import "./index.css";
import Cookies from './Components/Cookies';

const App = () => {
  return (
    <div>
    <Header />
    <About />
    <List />
    <Slider />
    <Footer/>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<><NavBar isBlack={true}/><About /><Footer/></>} />
        <Route path="/list" element={<><NavBar isBlack={true}/><List /><Footer/></>} />
        <Route path="/hotels" element={<><NavBar isBlack={true}/><Slider /><Footer/></>} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<Cookies />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);