import React from 'react';
import styles from '../Components/Styles/Footer.module.css';
import { Link } from 'react-router-dom';

import plus18 from './Assets/plus18.svg';
import telegram from './Assets/telegram.svg';
import instagram from './Assets/instagram.svg';

const Footer = () => {
  return (
      <footer className={styles.footerContainer} id='contacts'>
        <div className={styles.footerContent}>
         <div className={styles.footerText}>
          <p>Če nas želite kontaktirati ali nam poslati svoje komentarje, to storite. Lahko nas kontaktirate po e-pošti: <a href="mailto:bar-srece@gmail.com">bar-srece@gmail.com</a></p>
          <p>bar-srece.com uporablja piškotke za najboljšo izkušnjo. Z obiskom spletne strani se strinjate z uporabo <Link to="/cookies">piškotkov</Link>.</p>
         </div>
         <div className={styles.footerText}>
          <p>Vse informacije na naši spletni strani so namenjene zgolj informativnim namenom.</p>
         </div>
         <div className={styles.footerSocial}>
          <img src={plus18} alt="18plus" title='18plus'/>
          <div>
            <img src={telegram} alt="telegram" title='telegram'/>
            <img src={instagram} alt="instagram" title='instagram'/>
          </div>
         </div>
        </div>
        <Link to="/privacy" className={styles.privacyPolicy}>Politika Zasebnosti</Link>
      </footer>

  );
};

export default Footer;