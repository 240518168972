import React from 'react';
import styles from '../Components/Styles/Header.module.css';
import NavBar from './NavBar';

const Header = () => {
  return (
    <header className={styles.header}>
      <NavBar isBlack={false}/>
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>Najboljši hoteli za rekreacijo in zabavo</h1>
        <p className={styles.subtitle}>
          Ugotovite, kje se lahko sprostite z udobjem in užitkom
        </p>
      </div>
    </header>
  );
};

export default Header;
