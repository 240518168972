import React from 'react';
import styles from '../Components/Styles/Slider.module.css';
import hotel1 from './Assets/hotel1.webp';
import hotel2 from './Assets/hotel2.webp';
import hotel3 from './Assets/hotel3.webp';
import Footer from './Footer';
import NavBar from './NavBar';

const ImageGallery = () => {
  const hotels = [
    {
      id: '1',
      name: 'Teden Razkošja v Casino & Hotel Park, Nova Gorica',
      description: 'Uživajte v tednu razkošja in iger na srečo v Casino & Hotel Park. Ponudba vključuje bivanje v izboljšani sobi, vsakodnevni zajtrk, dostop do igralnice in 20% popust na vse wellness storitve. ',
      price: 'od 140 evrov na noč',
      image: hotel1,
    },
    {
      id: '2',
      name: 'Poletni Paket v Casino Riviera, Portorož',
      description: 'Preživite poletje na obali Jadranskega morja. Paket vključuje bivanje v standardni sobi, brezplačen dostop do plaže, zajtrk in večerjo ter 10% popust na vse vodne športe. Rezervirajte do konca meseca!',
      price: 'od 160 evrov na noč',
      image: hotel2,
    },
    {
      id: '3',
      name: 'VIP Večer v Casino Mond, Šentilj',
      description: 'Doživite ekskluziven večer v Casino Mond. Ponudba vključuje bivanje v luksuzni sobi, večerjo v restavraciji, 100 evrov igralnega kredita in dostop do VIP območja igralnice.',
      price: 'od 200 evrov na noč',
      image: hotel3,
    },
  ]

  return (
    <>
    <div className={styles.galleryContainer}>
      <h2 className='section__header'>Vroče ponudbe</h2>
      <div className={styles.cardsContainer}>
      {hotels.map(( item) => (
          <div className={styles.card} style={{backgroundImage:  `url(${item.image})` }} key={item.id}>
            <p className={styles.cardHeader}>{item.name}</p>
            <p className={styles.cardDescription}>{item.description}</p>
            <p className={styles.cardPrice}>{item.price}</p>
          </div>
      ))}
      </div>
    </div>
    </>
  );
};

export default ImageGallery;