import React from 'react';
import styles from '../Components/Styles/List.module.css';
import casinoImage from './Assets/list1.webp';
import casinoImage2 from './Assets/list2.webp';
import casinoImage3 from './Assets/list3.webp';
import casinoImage4 from './Assets/list4.webp';
import location from './Assets/location.svg';
import rating35 from './Assets/raiting3-5.svg';
import rating4 from './Assets/raiting4.svg';
import rating45 from './Assets/raiting4-5.svg';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import Footer from './Footer';
import NavBar from './NavBar';

const ratingdata = {
  3.5: rating35,
  '4.0': rating4,
  4.5: rating45,
};
const data = [
  {
    image: casinoImage,
    title: 'Casino Perla',
    address: 'Kidričeva Ulica 7, 5000 Nova Gorica',
    tags: [
      '24/7 Kazino',
      'Center Za Zdravje',
      'Različne Restavracije In Bari',
      'Konferenčne Dvorane',
    ],
    description:
      'Casino Perla Je Eden Največjih Zabaviščnih Kompleksov V Evropi, Ki Ponuja Luksuzne Sobe, Širok Izbor Restavracij In Številne Igralne Mize Ter Avtomate. Perla, Casino & Hotel Je Največji Igralni In Zabaviščni Center V Evropi, Ki Se Nahaja V Osrčju Nove Gorice V Sloveniji. To Je Edinstven Kompleks, Zgrajen V Obliki Križarjenja.',
    rating: '4.0',
    ratingText: 'Zelo Dobro',
    link: 'https://www.booking.com/hotel/si/perla-casino.pl.html',
  },
  {
    image: casinoImage2,
    title: 'Casino & Hotel Park, Nova Gorica',
    address: 'Delpinova ulica 5, 5000 Nova Gorica',
    tags: [
      'Sodobni kazino',
      'Sobe s panoramskimi razgledi',
      'Restavracija z mednarodno kuhinjo',
    ],
    description:
      'Ta hotel ponuja edinstveno kombinacijo iger na srečo in razkošja v mirnem in prijetnem okolju. Hotel je značilna elegantna nastanitev, sodobno opremo, namenske storitve in elegantno vzdušje, ali ste tukaj za casino, Kuhinja, poslovni ali spa objektov. ',
    rating: '3.5',
    ratingText: 'No',
    link: 'https://www.booking.com/hotel/pt/pestanacasinopark.pl.html',
  },
  {
    image: casinoImage3,
    title: 'Casino Bled',
    address: 'Cesta svobode 15, 4260 Bled',
    tags: [
      'Kazino z različnimi igrami',
      'Ekskluzivne restavracije',
      'Center za zdravje',
    ],
    description:
      'Nahaja se ob slikovitem Blejskem jezeru in ponuja ne le igre na srečo, temveč tudi osupljive razglede in možnosti za aktivno preživljanje prostega časa.',
    rating: '4.0',
    ratingText: 'Zelo dobro',
    link: 'https://www.booking.com/hotel/si/hotelparkbled.pl.html',
  },
  {
    image: casinoImage4,
    title: 'Casino Riviera',
    address: 'Obala 33, 6320 Portorož',
    tags: [
      'Obsežna igralnica',
      'Razkošne sobe s pogledom na morje',
      'Spa v centru za dobro počutje',
      'Zunanji bazen',
    ],
    description:
      'Casino Riviera je eleganten hotel, ki se nahaja ob obali Jadranskega morja v priljubljenem letovišču Portorož. Ponuja sproščeno vzdušje, vrhunsko igralnico in obilo možnosti za sprostitev.',
    rating: '4.5',
    ratingText: 'Okusno',
    link: 'https://www.booking.com/hotel/uy/rivera-casino-amp-resort-rivera.pl.html',
  },
];

function List() {
  return (
    <>
      <div className={styles.listSectionContainer}>
        <h2 className='section__header'>Boljši hoteli</h2>
        <div className={styles.container}>
          {data.map((item, index) => (
            <div key={index} className={styles.item}>
              <div className={styles.image}>
                <img src={item.image} alt={item.title} title={item.title}/>
              </div>
              <div className={styles.details}>
                <h1>{item.title}</h1>
                <p>
                  <img src={location} alt='location' title={item.address} />
                  {item.address}
                </p>
                <div className={styles.tags}>
                  {item.tags.map((tag, tagIndex) => (
                    <span key={tagIndex}>{tag}</span>
                  ))}
                </div>
                <p className={styles.hotelDescription}>{item.description}</p>
                <div className={styles.rating}>
                  <div className={styles.score}>{item.rating}</div>
                  <div className={styles.stars}>
                    <p className={styles.ratingText}>{item.ratingText}</p>
                    <img src={ratingdata[item.rating]} alt='raiting' title={ratingdata[item.rating]}/>
                  </div>

                  <a href={item.link} target='blank' className={styles.button}>
                    Za rezervacijo
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default List;
