import React from 'react';
import styles from '../Components/Styles/About.module.css';
import NavBar from './NavBar';
import Footer from './Footer';

const About = () => {
  return (
    <>
      <section className={styles.about}>
        <div className={styles.aboutContainer}>
          <h2 className='section__header'>O nas</h2>
          <p className={styles.description}>
            Smo ekipa navdušencev strastno o svetu iger na srečo in luksuznih
            počitnic. Naše poslanstvo je, da vam ponudimo podrobne in ažurne
            ocene najboljših igralniških hotelov v Sloveniji, da bo vaše bivanje
            tukaj nepozabno. Vsako igralnico in hotel obiščemo osebno, da vam
            zagotovimo najnovejše informacije o sobah, restavracijah, udobju in
            zabavnih programih. Sodelujemo z najboljšimi casino hoteli, da vam
            ponudimo ekskluzivne popuste in bonuse. Prizadevamo si, da bi bilo
            vaše bivanje v Sloveniji čim bolj udobno in vznemirljivo. Poskrbimo,
            da bodo vsi od počitnic dobili le pozitivna čustva in nepozabne
            vtise.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
